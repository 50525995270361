<script setup lang="ts">

const props = withDefaults(
  defineProps<{
    src: string;
    focusPoint?:
      | {
          x: number;
          y: number;
        }
      | null
      | undefined;
    crop?:
      | {
          width: number;
          height: number;
          type: string;
          x: number;
          y: number;
          rotate: number;
          scaleX: number;
          scaleY: number;
        }
      | null
      | undefined;
    width?: string | number;
    height?: string | number;
    alt?: string;
    classImg?: any;
    loading?: "lazy" | "eager" | undefined;
  }>(),
  {
    focusPoint: undefined,
    crop: undefined,
    width: "auto",
    height: "auto",
    alt: "",
    loading: "lazy",
  }
);

const modifiers = computed(() => {
  let modifiers: any = {};

  if (props.crop) {
    modifiers.crop = props.crop.width + ":" + props.crop.height;
    modifiers.gravity = (props.crop.type ? props.crop.type : 'nowe') + ":" + props.crop.x + ":" + props.crop.y;
  }

  modifiers.width = props.width !== 'auto' ? props.width : 0;
  modifiers.height = props.height !== 'auto' ? props.height : 0;

  return modifiers;
});
</script>

<template>
    <SharedProxyImg
      v-if="src"
      :style-img="[
        focusPoint ? 'object-position:' + focusPoint?.x + '% ' + focusPoint?.y + '%;' : '',
        width !== 'auto' ? 'width:' + width + 'px;' : '',
        width === 'auto' && height !== 'auto' ? 'height:' + height + 'px;' : '',
      ]"
      :src="src"
      :width="width"
      :height="height"
      :modifiers="modifiers"
      :alt="alt"
      :class-img="classImg"
      :loading="loading"
    />
</template>
